<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Pobranie {{ payment.p_number }}
          <v-spacer></v-spacer>
        </v-card-title>

        <v-expansion-panels multiple v-model="panels">
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ payment.p_number }} - Podsumowanie
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="start" justify="space-between">
                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Numer pobrania:</v-list-item-title>
                      <v-list-item-subtitle>{{ payment.p_number }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Data pobrania</v-list-item-title>
                      <v-list-item-subtitle>{{ payment.date_payed }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Wartość:</v-list-item-title>
                      <v-list-item-subtitle>{{ payment.price_net }} zł</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Zlecenia objęte pobraniem:</v-list-item-title>
                      <v-list-item-subtitle>
                        <div class="" v-for="transport in transports" :key="transport.id">
                          <router-link :to="'/transport/' + transport.id">{{
                            transport.number
                          }}</router-link>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="isAdmin">
                    <v-list-item-content>
                      <v-list-item-title>
                        Wydruk:
                        <v-icon @click="downloadPDF()"> mdi-file </v-icon>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-bottom-navigation>
      <v-btn :to="{ name: 'paymentsList' }" value="recent" v-if="isAdmin">
        <span>Powrót do listy</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>
      <v-btn :to="{ name: 'allPickupPayments' }" value="recent" v-else>
        <span>Powrót do listy</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
  import store from '../../store';

  import { mdiPackageVariant, mdiPin, mdiContacts } from '@mdi/js';

  import axios from 'axios';

  export default {
    props: {
      source: String,
    },
    data: () => ({
      icons: {
        mdiPackageVariant,
        mdiPin,
        mdiContacts,
      },
      panels: [0],
      payment: {},
      transports: [],
      isAdmin: store.getters.isAdmin,
    }),
    methods: {
      getPayment: function () {
        axios({
          url: this.$store.state.url + '/api/payment/pickup/get',
          data: {
            token: this.$store.state.token,
            params: {
              pickup_payment_id: this.$route.params.id,
            },
          },
          method: 'POST',
        }).then(resp => {
          if (resp.data.status === 200) {
            this.payment = resp.data.payments[0];
            this.getTransports(this.payment.pickup_payment_id);

            let date = new Date(this.payment.date_payed);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let dt = date.getDate();

            if (dt < 10) {
              dt = '0' + dt;
            }
            if (month < 10) {
              month = '0' + month;
            }

            this.payment.date_payed = dt + '-' + month + '-' + year;
          } else {
            this.$router.push('/');
          }
        });
      },
      getTransports: function (id) {
        axios({
          url: this.$store.state.url + '/api/transport/get',
          data: {
            token: this.$store.state.token,
            type: 'all',
            params: {
              pickup_payment_id: id,
            },
          },
          method: 'POST',
        }).then(resp => {
          resp.data.transports.forEach(t => {
            this.transports.push({
              id: t.transport_id,
              number: t.t_number,
            });
          });
        });
      },
      downloadPDF: function () {
        axios({
          url: this.$store.state.url + '/api/payment/pickup/get_pdf',
          data: { token: this.$store.state.token, id: this.payment.pickup_payment_id },
          method: 'POST',
          responseType: 'blob',
        })
          .then(resp => {
            if (resp.status === 200) {
              var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', `pickup_${this.payment.p_number}.pdf`);
              document.body.appendChild(fileLink);

              fileLink.click();
              fileLink.parentNode.removeChild(fileLink);
            } else {
              if (resp.data.message) alert(resp.data.message);
              else alert('Niepowiodło się');
            }
          })
          .catch(() => {
            alert('Niepowiodło się');
          });
      },
    },
    created() {
      this.getPayment();
    },
  };
</script>
<style>
  .v-expansion-panel .map {
    width: 100%;
    height: 300px;
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0);
    transition: 0.1s;
  }
  .v-expansion-panel.v-item--active .map {
    display: block;
    visibility: visible;
    transform: scaleY(1);
    opacity: 1;
  }
</style>
